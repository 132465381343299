<template>
  <div>
    <HomePageTop ref="tyop"></HomePageTop>
    <!-- 售后详情 -->
    <div id="aftersaledetails">
      <div class="navtop">
        <span><a href="/user/aftersale">{{ $fanyi('售后列表') }}</a></span> <span class="blank">></span>
        <span class="blank">{{ $fanyi('售后详情') }}</span>
      </div>
      <!-- 售后信息 -->
      <div class="Aftersalesinformation">
        <div class="header-title">
          <h2>{{ $fanyi('售后信息') }}</h2>
        </div>

        <!-- 表格区域 -->
        <div class="tablebox">
          <div class="title">
            <div>
              <span style="font-weight: 600">{{ $fanyi('配送单号') }} ： </span>
              <span><router-link :to="{ path: '/deliveryDetails', query: { pOrder_sn: orderdata.porder_sn } }"
                                 class="colorB4272B cursorPointer" style="text-decoration: underline;" target="_blank">
                  {{ orderdata.porder_sn }}
                </router-link></span>
            </div>
            <div>
              <span style="font-weight: 600">{{ $fanyi('售后编号') }} ： </span>
              <span> {{ orderdata.after_sale_sn }}</span>

            </div>
            <div>
              <span style="font-weight: 600">{{ $fanyi('售后完成时间') }} ：
              </span>
              <span> {{ orderdata.complete_at }}</span>

            </div>
          </div>
          <!-- 具体商品搜后 -->
          <div v-if="Number(orderdata.is_whole_porder) == 0" class="table">
            <el-table :data="orderdata.after_sale_detail" :header-cell-style="{
              'text-align': 'center',
              'font-size': '14px ',
              height: '56px',
              color: 'black',
              background: '#F0F0F0',
            }" style="width: 100%" :cell-style="{
  'text-align': 'center',
  color: 'black',
  'font-size': '14px',
  padding: '0',
  height: '100px',
  background: '#FFFFFF',
}">
              <!-- 订单号 -->
              <el-table-column :label="$fanyi('订单号')" prop="date" width="240">
                <template slot-scope="scope">
                  {{ scope.row.order_sn }}
                </template>
              </el-table-column>
              <!-- 番号 -->
              <el-table-column :label="$fanyi('番号')" prop="name" width="120">
                <template slot-scope="scope">
                  {{ scope.row.sorting }}
                </template>
              </el-table-column>
              <!-- 图片 -->
              <el-table-column :label="$fanyi('图片')" prop="address" width="120">
                <template slot-scope="scope">
                  <div v-if="scope.row.pic" class="imgbox">
                    <el-popover placement="top-start" trigger="hover" width="200">
                      <img v-if="scope.row.pic" :src="scope.row.pic" alt="" style="width: 180px;height: 180px;"/>
                      <img v-if="scope.row.pic" slot="reference" :src="scope.row.pic" alt=""/>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <!-- 商品属性 -->
              <el-table-column :label="$fanyi('商品属性')" prop="address" width="200">
                <template slot-scope="scope">
                  <div class="Commodityattribute">
                    <p v-for="(item, index) in scope.row.detail" :key="index">
                      <span>{{ item.key }} :</span> {{ item.value }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <!-- 出货数量 -->
              <el-table-column :label="$fanyi('出货数量')" prop="num" width="120">
              </el-table-column>
              <!-- 售后调整原因 -->
              <el-table-column :label="$fanyi('售后调整原因')" prop="address" width=" 288">
                <template slot-scope="scope">
                  <div class="yuanyin">
                    <textarea id="" v-model="scope.row.client_desc" :disabled="true" style="resize: none"></textarea>
                  </div>
                </template>
              </el-table-column>
              <!-- 问题图片 -->
              <el-table-column :label="$fanyi('问题图片')" prop="address">
                <template slot-scope="scope">
                  <div class="problemimg">
                    <el-popover v-for="(item, index) in scope.row.client_images" :key="index" placement="top-start"
                                trigger="hover" width="200">
                      <img v-if="item != null" :src="item" alt="" style="width: 180px;height: 180px;"/>
                      <img v-if="item != null" slot="reference" :src="item" alt=""/>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 整单详情 -->
          <div v-if="Number(orderdata.is_whole_porder) == 1" class="Wholeorder">
            <!-- 售后原因 -->
            <div class="Aftersalesreasons">
              <div class="title">{{ $fanyi('售后原因') }} :</div>
              <textarea v-model="orderdata.after_sale_detail[0].client_desc" :disabled="true" cols="30"
                        name="" style="resize: none"
                        rows="10"></textarea>
            </div>

            <!-- 反馈问题图片： -->
            <div class="Questionpicture">
              <div class="title">{{ $fanyi('反馈问题图片') }} :</div>
              <div v-if="orderdata?.after_sale_detail[0].client_images.some(
                (item) => item != null
              )
                " class="img-box">
                <el-popover v-for="(item, index) in orderdata?.after_sale_detail[0]
                  .client_images" placement="top-start" :key="index" width="200" trigger="hover">
                  <img :src="item" alt="" style="width: 300px;height: 300px;"/>
                  <img slot="reference" :src="item" alt=""/>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 具体赔款信息 -->
      <div class="Specificcompensationinformation">
        <div class="title">
          <h2>{{ $fanyi('具体赔款信息') }}</h2>
          <button @click="$router.push('/user/churujinList?order_sn=' + orderdata.porder_sn)">{{
              $fanyi('出入金记录')
            }}
          </button>
        </div>
        <div class="table">

          <el-table :data="user_billlist" :header-cell-style="{
          'text-align': 'center',
          'font-size': '14px ',
          height: '56px',
          color: 'black',
          background: '#F0F0F0',
        }" style="width: 100%" :cell-style="{
  'text-align': 'center',
  color: 'black',
  'font-size': '14px',
  padding: '0',
  height: '60px',
  background: '#FFFFFF',
}"><!-- 费用调整名义 -->
            <el-table-column prop="date" :label="$fanyi('费用调整名义')">
              <template slot-scope="scope">
                {{ scope.row.client_bill_reason }}
              </template>
            </el-table-column>
            <!-- 具体说明 -->
            <el-table-column prop="name" :label="$fanyi('具体说明')">
              <template slot-scope="scope">
                <!-- {{ scope.row.remark }} -->
                <div v-for="(item, index) in scope.row.adjust_detail" :key="index">
                  <p v-for="(i, ind) in item" :key="ind">{{ item[ind] }}</p>
                </div>
              </template>
            </el-table-column>
            <!-- 费用变更 -->
            <el-table-column :label="$fanyi('费用变更')" prop="address">
              <template slot-scope="scope">
                <span v-if="Number(scope.row.amount) > 0">+{{ scope.row.amount }}</span>
                <span v-if="Number(scope.row.amount) < 0">{{
                    scope.row.amount
                  }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 出货商品信息 -->
      <div class="Shippinginformation">
        <div class="title">
          <h2>{{ $fanyi('出货商品信息') }}</h2>
        </div>
        <div class="tablebox">
          <div class="table" style="padding: 0">
            <el-table :data="porder_detail" :header-cell-style="{
            'text-align': 'center',
            'font-size': '14px ',
            height: '56px',
            color: 'black',
            background: '#F0F0F0',
          }" style="width: 100%" :cell-style="{
  'text-align': 'center',
  color: 'black',
  'font-size': '14px',
  padding: '0',
  height: '120px',
  background: '#FFFFFF',
}">
              <!-- 订单号 -->
              <el-table-column :label="$fanyi('订单号')" width="200">
                <template slot-scope="scope">
                  {{ scope.row.order_sn }}
                </template>
              </el-table-column>
              <!-- 番号 -->
              <el-table-column :label="$fanyi('番号')" prop="name" width="60">
                <template slot-scope="scope">
                  {{ scope.row.sorting }}
                </template>
              </el-table-column>
              <!--  写真-->
              <el-table-column :label="$fanyi('写真')" prop="address" width="100">
                <template slot-scope="scope">
                  <div class="imgbox">

                    <el-popover placement="top-start" trigger="hover" width="200">
                      <img v-if="scope.row.pic" :src="scope.row.pic" alt="" style="width: 180px;height: 180px;"/>
                      <img v-if="scope.row.pic" slot="reference" :src="scope.row.pic" alt=""/>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <!-- 商品规格 -->
              <el-table-column :label="$fanyi('商品规格')" prop="address" width="160">
                <template slot-scope="scope">
                  <div class="Commodityattribute">
                    <p v-for="(item, index) in scope.row.detail" :key="index">
                      <span>{{ item.key }} :</span> {{ item.value }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <!-- 购入数据 -->
              <el-table-column :label="$fanyi('购入数据')" prop="address" width="160">
                <template slot-scope="scope">
                  <div class="Commodityattribute">
                    <p>{{ $fanyi('数量') }}: {{ scope.row.offer_num_bak }}</p>
                    <p>
                      {{ $fanyi('单价') }}: {{ scope.row.confirm_price }}
                      {{ $fanyi('元') }}
                    </p>
                    <p>
                      {{ $fanyi('国内运费') }} :{{ scope.row.confirm_freight }}
                      {{ $fanyi('元') }}
                    </p>
                    <p>
                      {{ $fanyi('小计') }} :
                      {{
                        (
                            Number(scope.row.offer_num_bak) *
                            Number(scope.row.confirm_price) +
                            Number(scope.row.confirm_freight)
                        ).toFixed(2)
                      }}{{ $fanyi('元') }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <!-- 标签信息 -->
              <el-table-column :label="$fanyi('标签信息')" prop="address" width="158">
                <template slot-scope="scope">
                  <div :key="tagIndex" class="tagSettingTableContainer"
                       v-for="(tagItem, tagIndex) in scope.row.newTagTable"
                       :style="tagIndex === scope.row.newTagTable.length - 1
                  ? ''
                  : 'margin-bottom: 10px'
                  " style="border-bottom: none">
                    <div v-for="(newTagItem, newTagIndex) in tagItem.table" :key="newTagIndex"
                         class="tagTableContainer flexAndCenter">
                      <div v-if="newTagItem.key !== 'type'" :title="newTagItem.value" class="u-line"
                           style="width: 148px">
                        {{ newTagItem.value }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- 发货数 -->
              <el-table-column :label="$fanyi('发货数')" prop="address" width="120">
                <template slot-scope="scope"> {{ scope.row.send_num }}</template>
              </el-table-column>
              <!-- 单番装箱备注 -->
              <el-table-column :label="$fanyi('单番装箱备注')" prop="address" width="230">
                <template slot-scope="scope">
                  <div class="yuanyin">
                  <textarea id="" v-model="scope.row.client_remark" name=""
                            style="width: 210px; height: 100px;resize: none"
                            :disabled="true"></textarea>
                  </div>
                </template>
              </el-table-column>
              <!-- 所在箱子 -->
              <el-table-column :label="$fanyi('所在箱')">
                <template slot-scope="scope">
                  <div class="box">
                    <p v-for="(item, index) in scope.row.in_porder_box" :key="index">
                      {{ $fanyi('箱号') }}{{ item.box_number }}：{{
                        item.in_box_num
                      }}{{ $fanyi('个') }}
                    </p>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 底部表格 -->
      <boxMessage :logisticsGroupTable="logistics_group" :table="porder_box" class="aftersaledetailsboxMessage"
                  style="margin-top: 26px"/>
      <!-- 操作栏 -->
      <div class="fixed-bottom">
        <div class="leftbox">
          <!-- 提出番数 -->
          <div>
            {{ $fanyi('提出番数') }} :
            <span>{{ porder_detail.length }}</span>
          </div>

          <!-- 提出数量 -->
          <div>
            {{ $fanyi('提出数量') }} :
            <span>{{ submitNum }}</span>
          </div>
        </div>

        <div class="rightbox">
          <!-- 费用 -->
          <div class="expense">
            <!--  -->
            <p>{{ $fanyi('国际运费') }} : <span class="red"> {{
                $fun.yenNumSegmentation(
                    pOrderPaymentInfo.international_logistics_fee_jpy
                )
              }}</span> 円 </p>
            <p style="display: flex;">
              {{ $fanyi('其他费用') }} : <span class="red"> {{ pOrderPaymentInfo.other_fee_jpy }}</span> 円

              <el-popover placement="bottom" trigger="hover">
                <div class="otherAmountDetailContainer">
                  <div class="otherPriceTitle">{{ $fanyi('其他费用明细') }}</div>
                  <div class="otherExpensesHead">
                    <ul class="flexAndCenter color333 fontSize14">
                      <li class="optionSelect">{{ $fanyi('名义') }}</li>
                      <li class="num">{{ $fanyi('费用（元）') }}</li>
                    </ul>
                  </div>
                  <!-- 单个商品 -->
                  <div class="otherExpensesFooter">
                    <ul v-for="(detailItem, index) in otherAmountDetailList" :key="index"
                        class="flexAndCenter color333"
                        style="background: transparent">
                      <li class="optionSelect">
                        {{ detailItem.desc }}
                      </li>
                      <li class="num">
                        {{ detailItem.fee }}
                      </li>
                    </ul>
                  </div>
                  <div class="footer">
                    <div>合計</div>
                    <div>{{ otherAmountPrice }}</div>
                  </div>
                </div>
                <div slot="reference" class="detailsText">
                  <span style="display: flex;margin-left: 5px;"> {{ $fanyi('详细') }}</span>
                </div>
              </el-popover>
            </p>
          </div>

          <!-- 合计 -->
          <div class="total">
            <p>{{ $fanyi('合计') }} : <span class="red">{{ pOrderPaymentInfo.sum_amount_jpy }}</span> 円</p>
            <p v-if="pOrderPaymentInfo.discounts_amount_jpy!=0">{{ $fanyi('抵扣金额') }} : <span class="red">
              {{
                pOrderPaymentInfo.discounts_amount_jpy == 0 ?
                    $fun.yenNumSegmentation(pOrderPaymentInfo.discounts_amount_jpy) : '-' +
                    $fun.yenNumSegmentation(pOrderPaymentInfo.discounts_amount_jpy)
              }} </span> 円</p>
            <p>{{ $fanyi('实际付款') }} : <span class="red"> {{
                $fun.yenNumSegmentation(pOrderPaymentInfo.pay_amount_jpy)
              }}</span> 円</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePageTop from '../../../../../components/head/HomePageTop.vue'
import boxMessage from '../../../views/deliveryList/views/DeliveryDetails/components/boxMessage.vue'

export default {
  components: {
    HomePageTop,
    boxMessage
  },
  data() {
    return {
      showtable: false,
      orderdata: {},
      after_sale_detail_feelist: [], // 赔款信息
      porder_detail: [], // 出货商品信息
      logistics_group: [], //箱子信息
      porder_box: [], // 箱子
      porderdata: {},
      submitNum: 0,
      pOrderPaymentInfo: {},
      otherAmountPrice: 0,
      otherAmountDetailList: [],
      user_billlist: [],
    }
  },
  created() {
    this.getData()
    this.storageDeliverDetail()
  },
  methods: {
    // 获取数据
    async getData() {
      this.loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading fontSize50',
        background: 'rgba(255, 255, 255, 0.7)',
      })
      const res = await this.$api.aftersaledetail({
        after_sale_sn: this.$route.query.after_sale_sn,
      })
      this.loading.close()
      if (res.code != 0) {
        this.$message.error(res.msg)
        return
      }
      for (let i = 0; i < res.data.after_sale_detail.length - 1; i++) {
        for (let j = res.data.after_sale_detail[i].client_images.length - 1; j >= 0; j--) {
          if (res.data.after_sale_detail[i].client_images[j] == null) {
            res.data.after_sale_detail[i].client_images.splice(j, 1)
          }
        }
      }
      this.orderdata = res.data
      this.user_billlist.push(res.data.user_bill)
    },
    // 获取配送单详情
    async storageDeliverDetail() {
      const res = await this.$api.storageDeliverDetail({
        porder_sn: this.$route.query.porder_sn,
      })
      if (res.code != 0) {
        this.$message.error(res.msg)
        return
      }
      this.porder_detail = res.data.porder_detail
      this.logistics_group = res.data.logistics_group
      this.porder_box = res.data.porder_box
      this.porderdata = res.data
      res.data.porder_detail.forEach((item) => {
        this.submitNum += item.send_num
        let arr = []
        item.porder_detail_tag.forEach((tagItem) => {
          let table = []
          table.push({key: '类别', value: tagItem.type_translate})
          table.push({key: '标签号', value: tagItem.no})
          table.push({key: '商品码', value: tagItem.goods_no})
          arr.push({table: table})
        })
        item.newTagTable = arr
      })
      res.data.porder_box.forEach((item) => {
        item.activities = []
        item.other_amount_detail.forEach((detailItem) => {
          this.otherAmountPrice += Number(detailItem.fee)
          this.otherAmountDetailList.push(detailItem)
        })
      })
      this.pOrderPaymentInfo = res.data.porder_amount
    },
  },
}
</script>
<style lang="scss">
.el-table__header {
  overflow: hidden;
}

.el-table__header-wrapper {
  border-radius: 10px 10px 0 0px !important;
}

.aftersaledetailsboxMessage {
  .orderLeftContainer {
    width: 961px !important;
  }

  .orderRightContainer {
    width: 412px !important;
  }

  .order {
    display: flex;
    justify-content: space-between;
  }
}
</style>
<style lang="scss" scoped>
#aftersaledetails {
  width: 1400px;
  margin: auto;
  min-height: 300px;
  padding-top: 30px;
  padding-bottom: 130px;

  .navtop {
    width: 1400px;
    height: 72px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    margin-bottom: 26px;

    span {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #999999;
      display: flex;
      margin-right: 20px;
    }

    .blank {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }

  .Aftersalesinformation {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 26px;

    .tablebox {
      padding: 0 26px;
      padding-bottom: 27px;

      .title {
        display: flex;

        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        align-items: center;
        justify-content: space-between;

        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 66px;
          width: 400px;
          font-size: 14px;

          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          img {
            width: 16px;
            height: 16px;
            margin-left: 20px;
          }
        }
      }

      .table {
        .imgbox {
          img {
            width: 60px;
            height: 60px;
            border: 1px solid #dfdfdf;
          }
        }

        .Commodityattribute {
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .yuanyin {
          textarea {
            width: 240px;
            height: 80px;

            border: 1px solid #dfdfdf;
            border-radius: 3px;
            padding: 10px;
          }
        }

        .problemimg {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          img {
            width: 60px;
            height: 60px;
            border: 1px solid #dfdfdf;
            margin-bottom: 10px;
          }
        }
      }
    }

    .header-title {
      width: 1400px;
      height: 72px;
      background: #ffffff;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      align-items: center;
      padding: 0 26px;
      border-bottom: 1px solid #f6f6f6;

      h2 {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        color: #333333;
      }
    }

    .Wholeorder {
      width: 100%;
      display: flex;

      .Aftersalesreasons,
      .Questionpicture {
        display: flex;
        min-width: 400px;
        margin-right: 73px;

        .title {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          height: 18px;
          font-weight: 600;
          margin-right: 16px;
        }

        textarea {
          width: 320px;
          height: 80px;
          background: #f9f9f9;
          border: 1px solid #dfdfdf;
          border-radius: 3px;
          padding: 9px;
        }

        .img-box {
          margin-left: 20px;
          display: flex;
          flex-wrap: wrap;

          img {
            margin-right: 10px;
            width: 60px;
            height: 60px;
            border: 1px solid #dfdfdf;
          }
        }
      }
    }
  }

  .Specificcompensationinformation {
    width: 1400px;

    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 26px;

    .title {
      width: 1400px;
      height: 72px;
      background: #ffffff;
      width: 1400px;
      height: 72px;

      border-bottom: 1px solid #f6f6f6;
      padding: 0 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }

      button {
        width: 100px;
        height: 30px;
        background: #b4272b;
        border-radius: 6px;
        display: flex;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        align-items: center;
        justify-content: center;
      }
    }

    .table {
      padding: 26px;
    }
  }

  .Shippinginformation {
    margin-bottom: 26px;

    .title {
      width: 1400px;
      height: 72px;
      background: #ffffff;
      width: 1400px;
      height: 72px;

      border-bottom: 1px solid #f6f6f6;
      padding: 0 26px;
      display: flex;
      align-items: center;

      h2 {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
    }

    .tablebox {
      padding: 26px;
      padding-bottom: 27px;
      background-color: #fff;

      .title {
        display: flex;
        align-items: center;

        justify-content: space-between;

        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 66px;
          width: 400px;
          font-size: 14px;

          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          img {
            width: 16px;
            height: 16px;
            margin-left: 20px;
          }
        }
      }

      .table {
        min-height: 180px;
        overflow: hidden;
        transition: all 0.5s linear;

        .imgbox {
          img {
            width: 60px;
            height: 60px;
            border: 1px solid #dfdfdf;
          }
        }

        .Commodityattribute {
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .box {
          width: 150px;
          height: 100px;
          background: #f9f9f9;
          border: 1px solid #dfdfdf;
          border-radius: 3px;
          overflow-y: scroll;
          padding: 10px;

          p {
            text-align-last: left;
          }
        }

        .tagSettingTableContainer {
          width: 148px;
          border: 1px solid #dfdfdf;

          .tagTableContainer {
            border-bottom: 1px solid #dfdfdf;
            width: 148px;
            height: 30px;
            font-size: 12px;

            div {
              width: 100%;
              height: 100%;
              line-height: 30px;
            }
          }
        }

        .problemimg {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          img {
            width: 60px;
            height: 60px;
            border: 1px solid #dfdfdf;
            margin-bottom: 10px;
          }
        }
      }

      .show {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
          width: 100px;
          height: 30px;
          background: #b4272b;
          border-radius: 0px 0px 6px 6px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;

          &:hover {

            cursor: pointer;
          }

          justify-content: center;

          i {
            margin-right: 5px;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  .footer-table {
    display: flex;

    justify-content: space-between;

    .left-table {
      width: 961px;
      min-height: 250px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 26px;
    }

    .right-table {
      width: 412px;
      min-height: 250px;
      background: linear-gradient(85deg, #faf1f1, #ffffff, #faf1f1);
      border: 1px solid rgba(180, 39, 43, 0.68);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 26px;
    }
  }
}

.otherAmountDetailContainer {
  color: #333333;

  .otherExpensesHead {
    ul {
      height: 40px;
      background: #f0f0f0;
      margin-top: 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      li {
        line-height: 40px;
        font-size: 14px;
        color: #333;
      }

      li:first-child {
        width: 200px;
        padding-left: 30px;
      }

      li:last-child {
        width: 120px;
        text-align: center;
      }
    }
  }

  .otherExpensesFooter {
    max-height: 400px;
    overflow-y: scroll;
    border-bottom: 1px solid #dfdfdf;

    ul {
      height: 50px;

      li {
        line-height: 50px;
        font-size: 14px;
        color: #333;
      }

      li:first-child {
        width: 200px;
        min-width: 200px;
        padding-left: 30px;
      }

      li:last-child {
        width: 120px;
        min-width: 120px;
        text-align: center;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 14px;
    width: 100%;

    div:first-child {
      width: 200px;
      min-width: 200px;
      padding-left: 30px;
      font-weight: bold;
    }

    div:last-child {
      width: 120px;
      min-width: 120px;
      color: #b4272b;
      font-weight: bold;
      text-align: center;
    }
  }

  .otherPriceTitle {
    margin-bottom: 13px;
    font-size: 14px;
  }
}

.fixed-bottom {
  width: 1400px;
  height: 112px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(10, 11, 34, 0.05);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  margin-top: 26px;

  .leftbox {
    display: flex;
    align-items: center;

    div {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      span {
        color: #b4272b;
        font-weight: 600;
      }

      &:first-child {
        margin-right: 52px;
      }
    }
  }

  .rightbox {
    display: flex;
    padding-top: 21px;

    .expense {
      min-width: 150px;

      p {
        margin-bottom: 13px;
        color: #333333;
        font-size: 16px;
      }
    }

    .xq {
      font-size: 14px;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }

    span {
      color: #b4272b;
      font-weight: 600;
    }

    .total {
      min-width: 150px;
      margin-left: 42px;
      text-align-last: left;

      p {
        margin-bottom: 13px;
        color: #333333;
        font-size: 16px;
      }

      .red {
        color: #b4272b;
      }
    }
  }
}

.maxheight {
  max-height: 1000000px !important;
  transition: all 0.5s linear;
}

</style>
